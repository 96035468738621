@import url(fontawesome-all.min.css);
@import url("https://fonts.googleapis.com/css?family=Raleway|Montserrat:700");

/*
	Indivisible by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #f4f4f4;
}

/* Type */

body,
input,
select,
textarea {
  color: #444;
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 14pt;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1680px) {
  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }
}

@media screen and (max-width: 360px) {
  body,
  input,
  select,
  textarea {
    font-size: 10pt;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #49c8ff !important;
  border-bottom-color: transparent;
}

strong,
b {
  color: #555;
  font-weight: 500;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #555;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 1em 0;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.9em;
}

h6 {
  font-size: 0.7em;
}

@media screen and (max-width: 736px) {
  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.25em;
  }
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: solid 4px #cccccc;
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  background: rgba(144, 144, 144, 0.075);
  border-radius: 8px;
  border: solid 1px #cccccc;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1em 1.5em;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px #cccccc;
  margin: 2em 0;
}

hr.major {
  margin: 3em 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Section/Article */

section.special,
article.special {
  text-align: center;
}
header {
  margin: 3em 0;
}

header > :first-child {
  margin-bottom: 0;
}

header > :first-child:after {
  content: "";
  display: block;
  background-color: #ffffff;
  height: 1px;
  margin: 1.5rem 0 1.75rem 0;
  width: 5rem;
}

.special header > :first-child:after {
  margin-left: auto;
  margin-right: auto;
}

header p {
  font-size: 0.9em;
  letter-spacing: 0.25em;
  margin-bottom: 0;
  text-transform: uppercase;
}

@media screen and (max-width: 736px) {
  header {
    margin: 2em 0;
  }

  header > :first-child:after {
    margin: 1.25rem 0 1.5rem 0;
  }

  header p {
    font-size: 0.8em;
  }

  header p br {
    display: none;
  }
}

/* Form */

form {
  margin: 0 0 2em 0;
}

form > :last-child {
  margin-bottom: 0;
}

form > .fields {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: calc(100% + 3em);
  margin: -1.5em 0 2em -1.5em;
}

form > .fields > .field {
  -moz-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  -moz-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  padding: 1.5em 0 0 1.5em;
  width: calc(100% - 1.5em);
}

form > .fields > .field.half {
  width: calc(50% - 0.75em);
}

form > .fields > .field.third {
  width: calc(100% / 3 - 0.5em);
}

form > .fields > .field.quarter {
  width: calc(25% - 0.375em);
}

@media screen and (max-width: 480px) {
  form > .fields {
    width: calc(100% + 3em);
    margin: -1.5em 0 2em -1.5em;
  }

  form > .fields > .field {
    padding: 1.5em 0 0 1.5em;
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.half {
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.third {
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.quarter {
    width: calc(100% - 1.5em);
  }
}

label {
  color: #555;
  display: block;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0 0 1em 0;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: rgba(144, 144, 144, 0.075);
  border-radius: 8px;
  border: none;
  border: solid 1px #cccccc;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="tel"]:invalid,
input[type="search"]:invalid,
input[type="url"]:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="url"]:focus,
select:focus,
textarea:focus {
  border-color: #49c8ff;
  box-shadow: 0 0 0 1px #49c8ff;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath \='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23cccccc' /%3E%3C/svg%3E");
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  height: 2.75em;
  padding-right: 2.75em;
  text-overflow: ellipsis;
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

select option {
  color: #555;
  background: #f4f4f4;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select {
  height: 2.75em;
}

textarea {
  padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  text-decoration: none;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 300;
  margin: 0 0 0.5em 0;
  padding-left: 2.9em;
  padding-right: 0.75em;
  position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  background: rgba(144, 144, 144, 0.075);
  border-radius: 8px;
  border: solid 1px #cccccc;
  content: "";
  display: inline-block;
  height: 1.65em;
  left: 0;
  line-height: 1.58125em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1.65em;
  font-size: 1em;
  letter-spacing: 0;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  background: #555;
  border-color: #555;
  color: #ffffff;
  content: "\f00c";
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
  border-color: #49c8ff;
  box-shadow: 0 0 0 1px #49c8ff;
}

input[type="checkbox"] + label:before {
  border-radius: 4px;
}

input[type="radio"] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  color: #bbb !important;
  opacity: 1;
}

:-moz-placeholder {
  color: #bbb !important;
  opacity: 1;
}

::-moz-placeholder {
  color: #bbb !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbb !important;
  opacity: 1;
}

.formerize-placeholder {
  color: #bbb !important;
  opacity: 1;
}

/* Box */

.box {
  border-radius: 8px;
  border: solid 1px #cccccc;
  margin-bottom: 2em;
  padding: 1.5em;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.icon.solid:before {
  font-weight: 900;
}

.icon.brands:before {
  font-family: "Font Awesome 5 Brands";
}

.icon > .label {
  display: none;
}

/* Image */

.image {
  border-radius: 8px;
  border: 0;
  display: inline-block;
  position: relative;
}

.image img {
  border-radius: 8px;
  display: block;
}

.image.left,
.image.right {
  max-width: 40%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  padding: 0 1.5em 1em 0;
  top: 0.25em;
}

.image.right {
  float: right;
  padding: 0 0 1em 1.5em;
  top: 0.25em;
}

.image.fit {
  display: block;
  margin: 0 0 2em 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
}

.image.main {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px #cccccc;
  padding: 0.5em 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 2em 0;
}

dl dt {
  display: block;
  font-weight: 500;
  margin: 0 0 1em 0;
}

dl dd {
  margin-left: 2em;
}

/* Actions */

ul.actions {
  cursor: default;
  list-style: none;
  margin-left: -1em;
  margin-top: -1em;
  padding-left: 0;
}

ul.actions li {
  display: inline-block;
  margin: 1em 0 0 1em;
  padding-left: 0;
  vertical-align: middle;
}

ul.actions li:last-child {
  padding-right: 0;
}

ul.actions.stacked {
  margin-top: 0;
  margin-left: 0;
}

ul.actions.stacked li {
  display: block;
  margin: 0;
  padding: 1.5em 0 0 0;
  position: relative;
}

ul.actions.stacked li:first-child {
  padding-top: 0;
}

ul.actions.stacked li > * {
  margin-bottom: 0;
}

ul.actions.stacked li > .button {
  min-width: 15em;
}

ul.actions.stacked.special li:after {
  background-color: #cccccc;
  content: "";
  display: block;
  height: 1.5em;
  left: 50%;
  position: absolute;
  top: 100%;
  width: 1px;
}

ul.actions.stacked.special li:last-child:after {
  display: none;
}

@media screen and (max-width: 736px) {
  ul.actions.stacked.special li {
    padding: 1em 0 0 0;
  }

  ul.actions.stacked.special li:after {
    height: 1em;
  }
}

ul.actions.animated.spinY li {
  -moz-perspective: 1000px;
  -webkit-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
}

ul.actions.animated.spinY li a {
  -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease, -moz-transform 0.5s ease-in;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease, -webkit-transform 0.5s ease-in;
  -ms-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease, -ms-transform 0.5s ease-in;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease,
    transform 0.5s ease-in;
}

ul.actions.animated.spinY li a.active {
  -moz-transform: rotateY(480deg);
  -webkit-transform: rotateY(480deg);
  -ms-transform: rotateY(480deg);
  transform: rotateY(480deg);
}

ul.actions.animated.spinX li {
  -moz-perspective: 1000px;
  -webkit-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
}

ul.actions.animated.spinX li a {
  -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease, -moz-transform 0.5s ease-in;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease, -webkit-transform 0.5s ease-in;
  -ms-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease, -ms-transform 0.5s ease-in;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease,
    transform 0.5s ease-in;
}

ul.actions.animated.spinX li a.active {
  -moz-transform: rotateX(480deg);
  -webkit-transform: rotateX(480deg);
  -ms-transform: rotateX(480deg);
  transform: rotateX(480deg);
}

/* Icons */

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  padding-left: 0;
}

ul.icons li .icon {
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
}

ul.icons li .icon:before {
  font-size: 1.25rem;
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2em 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px #cccccc;
  border-left: 0;
  border-right: 0;
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(144, 144, 144, 0.075);
}

table td {
  padding: 0.75em 0.75em;
  text-align: left;
}

table th {
  color: #555;
  font-size: 0.9em;
  font-weight: 500;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: left;
}

table thead {
  border-bottom: solid 2px #cccccc;
}

table tfoot {
  border-top: solid 2px #cccccc;
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px #cccccc;
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease;
  -ms-transition: background-color 0.2s ease, box-shadow 0.2s ease,
    color 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  background-color: transparent;
  border-radius: 8px;
  border: 0;
  box-shadow: inset 0 0 0 1px #cccccc;
  color: #555 !important;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 500;
  height: 3.5em;
  letter-spacing: 0.25em;
  line-height: 3.5em;
  padding: 0 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5em;
}

input[type="submit"].back,
input[type="reset"].back,
input[type="button"].back,
button.back,
.button.back {
  padding-left: 4.75em;
}

input[type="submit"].back:before,
input[type="reset"].back:before,
input[type="button"].back:before,
button.back:before,
.button.back:before {
  -moz-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='112px' height='96px' viewBox='0 0 112 96' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %23cccccc%3B stroke-width: 4px %7D%3C/style%3E%3Cline x1='0' y1='46' x2='112' y2='46' /%3E%3Cline x1='24' y1='22' x2='0' y2='46' /%3E%3Cline x1='24' y1='70' x2='0' y2='46' /%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  height: inherit;
  left: 2em;
  opacity: 1;
  position: absolute;
  top: 0;
  vertical-align: middle;
  width: 2em;
}

input[type="submit"].back:hover:before,
input[type="submit"].back.active:before,
input[type="reset"].back:hover:before,
input[type="reset"].back.active:before,
input[type="button"].back:hover:before,
input[type="button"].back.active:before,
button.back:hover:before,
button.back.active:before,
.button.back:hover:before,
.button.back.active:before {
  opacity: 0;
}

input[type="submit"].back:hover:after,
input[type="submit"].back.active:after,
input[type="reset"].back:hover:after,
input[type="reset"].back.active:after,
input[type="button"].back:hover:after,
input[type="button"].back.active:after,
button.back:hover:after,
button.back.active:after,
.button.back:hover:after,
.button.back.active:after {
  opacity: 1;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.7em;
}

input[type="submit"].large,
input[type="reset"].large,
input[type="button"].large,
button.large,
.button.large {
  font-size: 1em;
}

input[type="submit"]:hover,
input[type="submit"].active,
input[type="reset"]:hover,
input[type="reset"].active,
input[type="button"]:hover,
input[type="button"].active,
button:hover,
button.active,
.button:hover,
.button.active {
  box-shadow: inset 0 0 0 1px #49c8ff;
  color: #49c8ff !important;
}

input[type="submit"]:active,
input[type="submit"].active,
input[type="reset"]:active,
input[type="reset"].active,
input[type="button"]:active,
input[type="button"].active,
button:active,
button.active,
.button:active,
.button.active {
  background-color: rgba(73, 200, 255, 0.05);
}

input[type="submit"].primary,
input[type="reset"].primary,
input[type="button"].primary,
button.primary,
.button.primary {
  box-shadow: none;
  background-color: #555;
  color: #f4f4f4 !important;
}

input[type="submit"].primary:hover,
input[type="reset"].primary:hover,
input[type="button"].primary:hover,
button.primary:hover,
.button.primary:hover {
  background-color: #49c8ff;
}

input[type="submit"].primary:active,
input[type="reset"].primary:active,
input[type="button"].primary:active,
button.primary:active,
.button.primary:active {
  background-color: #16b9ff;
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.25;
}

/* Wrapper */

@media screen and (max-width: 1280px) {
  #wrapper {
    padding: 1.5em;
  }
}

@media screen and (max-width: 980px) {
  #wrapper {
    padding: 2em;
  }
}

@media screen and (max-width: 736px) {
  #wrapper {
    padding: 1em;
  }
}

/* Footer */

#footer .copyright {
  font-size: 0.8em;
  letter-spacing: 0.25em;
  margin-bottom: 0;
  text-transform: uppercase;
}

#footer.inactive {
  opacity: 0;
}

@media screen and (max-width: 1680px) {
  #footer {
    margin: 2em 0 0 0;
  }
}

@media screen and (max-width: 1280px) {
  #footer {
    margin: 1.5em 0 0 0;
  }
}

body.is-preload-0 #footer {
  opacity: 0;
}

.box {
  background: #ffffff;
  color: #39454b;
  padding: 2em;
}

.main > .content {
  width: 45em;
  max-width: calc(100% - 4em);
  margin: 0 auto;
}

.main > .content > :last-child {
  margin-bottom: 0;
}

.main.style1.inactive > .content {
  opacity: 0;
}

.main.style1 h2 {
  font-size: 4.25em;
}
.main.style2 {
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  padding: 3em 0 3em 0;
  overflow: hidden;
}

.main.style2 > .content {
  -moz-transition: -moz-transform 1s ease;
  -webkit-transition: -webkit-transform 1s ease;
  -ms-transition: -ms-transform 1s ease;
  transition: transform 1s ease;
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  width: 35%;
  margin: 0;
}

.main.style2.left {
  -moz-justify-content: -moz-flex-start;
  -webkit-justify-content: -webkit-flex-start;
  -ms-justify-content: -ms-flex-start;
  justify-content: flex-start;
}

.main.style2.right {
  -moz-justify-content: -moz-flex-end;
  -webkit-justify-content: -webkit-flex-end;
  -ms-justify-content: -ms-flex-end;
  justify-content: flex-end;
}

.main.style2.inactive.left > .content {
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.main.style2.inactive.right > .content {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.main.style3 {
  text-align: center;
  padding: 6em 0 6em 0;
}

.main.style3 .content > header2 {
  margin-bottom: 2em;
}

.main.style3.primary {
  background: #ffffff;
}

.main.style3.secondary {
  background: #f5f6f7;
}

#header2 {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  height: 3em;
  line-height: 3em;
  box-shadow: 0 0 0.15em 0 rgba(0, 0, 0, 0.1);
}

#header2 h1 a {
  font-size: 1.25em;
}

#header2 nav {
  position: absolute;
  right: 0.5em;
  top: 0;
  height: 3em;
  line-height: 3em;
}

#header2 nav ul {
  margin: 0;
}

#header2 nav ul li {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.9em;
}

#header2 nav ul li a {
  display: block;
  color: inherit;
  text-decoration: none;
  height: 3em;
  line-height: 3em;
  padding: 0 0.5em 0 0.5em;
  outline: 0;
}

@media screen and (max-width: 736px) {
  #header2 {
    height: 2.5em;
    line-height: 2.5em;
  }

  #header2 h1 {
    text-align: center;
    position: relative;
    left: 0;
    top: 0;
    height: 2.5em;
    line-height: 2.5em;
  }

  #header2 h1 a {
    font-size: 1em;
  }

  #header2 nav {
    display: none;
  }
}


.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;

}

.site-content {
  flex: 1;
}
