.spotlight {
    padding: 7rem 0;
    position: relative;
}

    .spotlight > .inner {
        display: -moz-flex;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 75rem;
        width: calc(100% - 4rem);
    }

    .spotlight h2 {
        -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
        -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
        -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        margin-bottom: 0;
    }

    .spotlight .image {
        border-radius: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 45%;
    }

        .spotlight .image img {
            -moz-object-fit: cover;
            -webkit-object-fit: cover;
            -ms-object-fit: cover;
            object-fit: cover;
            border-radius: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

    .spotlight .content {
        padding: 5rem 5rem 2.5rem 5rem ;
        -moz-transition: opacity 0.5s ease-out, -moz-transform 0.5s ease-out;
        -webkit-transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
        -ms-transition: opacity 0.5s ease-out, -ms-transform 0.5s ease-out;
        transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        background-color: #fff;
        border-radius: 4px;
        position: relative;
        z-index: 1;
        width: 55%;
    }

    .spotlight.odd .image {
        right: 0;
    }

    .spotlight.even > .inner {
        -moz-flex-direction: row-reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .spotlight.even .image {
        left: 0;
    }

    .spotlight.accent1 {
        background-color: #946cc1;
    }

        .spotlight.accent1 h2 {
            color: #ffffff;
        }

    .spotlight.accent2 {
        background-color: #6e9ff0;
    }

        .spotlight.accent2 h2 {
            color: #ffffff;
        }

    .spotlight.accent3 {
        background-color: #5eb6df;
    }

        .spotlight.accent3 h2 {
            color: #ffffff;
        }

    .spotlight.accent4 {
        background-color: #67cfc9;
    }

        .spotlight.accent4 h2 {
            color: #ffffff;
        }

    .spotlight.is-inactive h2 {
        -moz-transform: translateY(-2rem);
        -webkit-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        transform: translateY(-2rem);
        opacity: 0;
    }

    .spotlight.is-inactive .content {
        -moz-transform: translateY(2rem);
        -webkit-transform: translateY(2rem);
        -ms-transform: translateY(2rem);
        transform: translateY(2rem);
        opacity: 0;
    }

    @media screen and (max-width: 1280px) {

        .spotlight {
            padding: 5.5rem 0;
        }

            .spotlight > .inner {
                -moz-justify-content: -moz-flex-start;
                -webkit-justify-content: -webkit-flex-start;
                -ms-justify-content: -ms-flex-start;
                justify-content: flex-start;
            }

            .spotlight h2 {
                padding: 0 3rem;
                width: 40%;
                text-align: center;
            }

            .spotlight .content {
                padding: 4rem 4rem 1.5rem 4rem ;
                width: 45%;
            }

    }

    @media screen and (max-width: 980px) {

        .spotlight {
            padding: 0;
            text-align: center;
        }

            .spotlight > .inner {
                display: block;
                max-width: none;
                width: 100%;
            }

            .spotlight h2 {
                padding: 2.5rem 2.5rem;
                width: 100%;
            }

                .spotlight h2 br {
                    display: none;
                }

            .spotlight ul.actions {
                -moz-justify-content: center;
                -webkit-justify-content: center;
                -ms-justify-content: center;
                justify-content: center;
                width: 100%;
                margin-left: 0;
            }

                .spotlight ul.actions li:first-child {
                    padding-left: 0;
                }

            .spotlight .image {
                display: block;
                height: 40vh;
                min-height: 20rem;
                width: 100%;
                position: relative;
            }

            .spotlight .content {
                padding: 3rem 2.5rem 0.5rem 2.5rem ;
                border-radius: 0;
                width: 100%;
            }

                .spotlight .content h3 br {
                    display: none;
                }

            .spotlight.is-inactive h2 {
                -moz-transform: none;
                -webkit-transform: none;
                -ms-transform: none;
                transform: none;
                opacity: 0;
            }

            .spotlight.is-inactive .content {
                -moz-transform: none;
                -webkit-transform: none;
                -ms-transform: none;
                transform: none;
                opacity: 0;
            }

    }

    @media screen and (max-width: 736px) {

        .spotlight h2 {
            padding: 1.5rem;
        }

        .spotlight .content {
            padding: 2rem 1.5rem 0.1rem 1.5rem ;
        }

    }
